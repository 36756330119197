<template>
  <div class="mb-3 card">
    <div>
      <!-- Table -->
      <Datatable ref="happyHourDatatable"
                 :api-endpoint="ENDPOINTS.DATATABLES.ERP.HAPPYHOUR"
                 :datatable-headers="datatableHeaders"
                 :headers="datatableHeaders"
                 :excel-columns="excelColumns"
                 excel-file-name="happyH"
                 @editEntry="editEntry"
                 @deleteEntry="entryDelete"
                 @deleteEntries="entriesDelete"
                 show-delete-buttons
                 show-edit-buttons
                 :permissionDelete="this.$store.getters['permissions/checkPermission']('ware_delete')"
                 :permissionEdit="this.$store.getters['permissions/checkPermission']('ware_edit')"
      >
        <template v-slot:item.price="{item}">
          {{ item.mode == 'Preis' ? item.discountValue : '' }}
        </template>
        <template v-slot:item.percentage="{item}">
          {{  item.mode != 'Preis' ? item.discountValue : ''   }}
        </template>
      </Datatable>

    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from '../../../config'
import {Events} from "../../../plugins/events";
import Datatable from "../../datatable/Datatable";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  components: {
    Datatable
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,

      id: null,
      tab: 0,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('erp.lang_happyHourName'), value: "name"},
        {text: this.$t('settings.lang_settings_activ'), value: "isActive"},
        {text: this.$t('generic.lang_discountType'), value: "mode"},
        {text: this.$t('generic.lang_from'), value: "from",hide: true},
        {text: this.$t('generic.lang_to'), value: "to",hide: true},
        {text: this.$t('generic.lang_days'), value: "days",hide: true},
        {text: this.$t('erp.lang_discountValue'), value: "discountValue", hide: true},
        {text: this.$t('generic.lang_discount') + ' ' + this.$store.getters['settings/currencyData']('symbol'), value: "price"},
        {text: this.$t('generic.lang_discountInPercent'), value: "percentage"},
      ],
      excelColumns: [
        {
          label: "ID",
          field: "uuid",
        },
        {
          label: this.$t('erp.lang_happyHourName'),
          field: "regulateName",
        },
        {
          label: this.$t('settings.lang_settings_activ'),
          field: "isActive",
        },
        {
          label: this.$t('generic.lang_discountType'),
          field: "discountType",
        },
        {
          label: this.$t('erp.lang_discountValue'),
          field: "discountValue",
        },
      ],
    }
  },
  methods: {
    editEntry(entry) {
      this.id = entry.id;
      this.$router.push({name: 'erp.baseData.happyHour.edit', params: {id: this.id}});
    },

    entryDelete(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_happyHourDeletedHead'),
        text: this.$t('erp.lang_happyHourDeletedBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.id);
          }
          this.axios.post(ENDPOINTS.ERP.HAPPYHOUR.DELETE, {
            happyHourUUIDs: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_happyHourDeleted'),
                color: "success"
              });

              this.$refs.happyHourDatatable.getDataFromApi();
              this.$refs.happyHourDatatable.resetSelectedRows();
              this.resetData();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },

    resetData: function () {
    },
    showList: function () {
    },

  },
}
</script>